import React from 'react';
import Head from 'next/head';
import { withRouter } from 'next/router';
import lodash from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FiltersBar } from 'tutortime-filters-library';
import {
  composeBaseURL,
  composeURLQuery,
} from 'tutortime-filters-library/helpers/slug';
import { getTime24 } from 'tutortime-filters-library/helpers/filters';

import theme from 'theme';
import TutorCard from 'components/TutorCard';
import FiltersModal from 'components/FiltersModal';
import Analytics from 'helpers/analytics';
import Cookies from 'helpers/cookies';
import {
  findInObjectArray,
  getURLParams,
} from 'helpers/slug';

import styles from './styles';

class Tutors extends React.Component {
  state = {
    init: false,
    forcedFilters: false,
  }

  async componentDidMount() {
    const {
      items = [],
      fetchItems,
      fetchGroups,
      fetchGrades,
      fetchLevels,
    } = this.props;

    await fetchGroups();
    await fetchGrades();
    await fetchLevels();

    this.getFiltersFromSlug();

    if (!items.length) {
      fetchItems();
    }

    Analytics.pageView();
    this.setState({
      init: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters } = this.props;
    const { init } = this.state;

    if (!lodash.isEqual(prevProps.filters, filters)) {
      this.updateTutorList();
    }

    const { gradeId, subjectId, priceId, dates, time } = filters;
    const emptyFilters = !gradeId && !subjectId && !priceId && (!dates.length || !time);
    const firstVisit = Cookies.read('visited');
    if (!prevState.init && init && emptyFilters && !firstVisit) {
      this.openFiltersModal(true);
      Cookies.write('visited', true);
    }
  }

  openFiltersModal = (forced = false) => {
    const { router } = this.props;
    const { forcedFilters } = this.state;
    const { asPath, query } = router;

    if (forcedFilters !== forced) {
      this.setState({
        forcedFilters: forced,
      });
    }

    const baseUrl = asPath.split('?')[0];

    router.push(
      {
        query: {
          view: 'get-started',
          ...query,
        },
      },
      {
        pathname: baseUrl,
        query: {
          view: 'get-started',
          ...query,
        },
      },
      {
        shallow: true,
      },
    );
  }

  updateTutorList = () => {
    const { init } = this.state;
    const {
      fetchItems,
      resetTutors,
      grades,
      groups,
      filters,
    } = this.props;

    const urlParams = {
      gradesList: grades,
      groups,
      ...filters,
      subject: filters.subjectId,
      grade: filters.gradeId,
    };

    const baseUrl = composeBaseURL(urlParams);
    const query = composeURLQuery(urlParams);
    this.updateUrl(baseUrl, query);

    if (init) {
      resetTutors();
      fetchItems();
    }
  }

  updateUrl = (baseUrl, query) => {
    const { router } = this.props;

    if (baseUrl === '/') {
      router.replace({
        pathname: baseUrl,
        query,
      });
      return;
    }

    router.replace({
      query,
    }, {
      pathname: baseUrl,
      query,
    });
  }

  getSubjectFromSlug = slug => {
    const { groups = [] } = this.props;

    if (!slug || !slug.length) {
      return {};
    }

    const subject = findInObjectArray(groups, 'title', slug[slug.length - 1], true)?.id;
    if (!subject) {
      return {};
    }

    return { subject };
  }

  getScheduleFromQuery = () => {
    const { router } = this.props;
    const { schedule: scheduleString } = router.query;
    if (!scheduleString) {
      return {
        dates: [],
        time: null,
      };
    }

    const schedule = JSON.parse(scheduleString);

    const dates = schedule.map(item => {
      return new Date(item.date);
    });

    const { slotFrom, slotTo } = schedule[0];
    const startTime = getTime24(slotFrom);
    const endTime = getTime24(slotTo);
    const time = `${startTime} - ${endTime}`;

    return { dates, time };
  }

  getFiltersFromSlug = () => {
    const { router, grades = [], levels = [], setFilters } = this.props;
    const {
      slug,
      level: levelName,
      grade: gradeName,
    } = getURLParams(router.query);
    const { dates, time } = this.getScheduleFromQuery();
    const { subject } = this.getSubjectFromSlug(slug);
    const grade = findInObjectArray(grades, 'title', gradeName, true)?.id;
    const level = findInObjectArray(levels, 'name', levelName, true)?.id;

    setFilters({
      subject: subject || null,
      grade: grade || null,
      price: level || null,
      dates: dates || null,
      time: time || null,
    });
  }

  fetchNext = async () => {
    const { fetchItems, meta } = this.props;
    const { currentPage = 1 } = meta;

    fetchItems(currentPage + 1);
  }

  onSelectFilters = filters => {
    const { router, setFilters } = this.props;
    router.back();
    setTimeout(() => {
      setFilters(filters);
    }, 100);
  }

  getAllFilters = () => {
    const { filters, grades, groups } = this.props;

    return {
      subject: filters.subjectId,
      grade: filters.gradeId,
      dates: filters.dates,
      time: filters.time,
      isRWB: filters.isRWB,
      weekValue: filters.weekValue,
      gradesList: grades,
      groups,
    };
  }

  render() {
    const { forcedFilters } = this.state;
    const { router, items = [], meta = {}, noContent, setFilters, classes } = this.props;
    const hasMore = meta.currentPage < meta.totalPages;
    const { view } = router.query;

    const filters = this.getAllFilters();

    return (
      <>
        <Head>
        <title>
          Find a tutor to help my child | TutorTime
        </title>
        <meta name="description" content="TutorTime provides high quality online and home tutoring, at an affordable price, to help children unlock their full potential." />
        <meta name="keywords" content="Find a tutor to help my child | TutorTime, Find a tutor to help my child, Find a tutor, help my child, TutorTime, online and home tutoring, Online tutoring, TutorTime tutors, tutor time tutors, Naplan, homeschooling, Tutor jobs, HSC Tutoring, search for a tutor, Math tutor, English tutor, Chemistry tutor, Physics tutor, Tutoring services, HSC tutors, ESL tutors, Science tutors, Biology tutors, History tutors, Geography tutors, Commerce tutors, NAPLAN 3, NAPLAN 5, NAPLAN 7, NAPLAN 9, Economics tutor, Home shooling, French tutors, Spanish tutors, Visual Arts tutors, Music tutors, Piano tutors, Creative Writing tutors, Singing tutors, Study skills, Public speaking, Online Parent consultation, ATAR tutors, Book a tutor, Find a tutor in Sydney, TutorTime tutor, Tutoring in Australia, Face to face learning, Home tutoring, Mandarin tutors, tutor , ATAR , learning support teacher, educator, Bachelor of Science, Bachelor of Law, teacher, English tutor, athlete, university student, primary school teacher, HSC, ib, Kambala, Scots, Cranbrook, Sydney Grammar, Maths tutor, USYD" />
        </Head>
        <main>
          <div className={classes.titleRow}>
            <Typography component="h1" variant="h1" className={classes.title}>
              {theme.palette.searchTitle ? theme.palette.searchTitle : 'Explore Tutors'}
            </Typography>
            <Button
              className={classes.filterButton}
              onClick={() => this.openFiltersModal(false)}
              variant="contained"
            >
              Search
            </Button>
          </div>

          <FiltersBar
            theme={theme.theme}
            filters={filters}
            setFilters={setFilters}
          />

          <Typography component="h2" variant="h2" className={classes.title}>
            {theme.palette.searchSubTitle ? theme.palette.searchSubTitle : 'To book a particular tutor, click Book'}
          </Typography>

          {view === 'get-started' && (
            <FiltersModal
              open
              theme={theme.theme}
              onClose={this.onSelectFilters}
              forced={forcedFilters}
            />
          )}

          {noContent && (
            <h4 className={classes.loader}>No results found</h4>
          )}

          <InfiniteScroll
            className={classes.list}
            dataLength={items.length}
            next={this.fetchNext}
            hasMore={hasMore}
            loader={<h4 className={classes.loader}>Loading...</h4>}
          >
            {items.map(item => (
              <TutorCard
                key={item.id}
                {...item}
              />
            ))}
          </InfiniteScroll>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(Tutors));
