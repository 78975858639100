import axios from 'config/axios';
import theme from 'theme';
import Analytics, { Events } from 'helpers/analytics';
import { TEACHERS_THEME } from 'helpers/constants';
import {
  FETCH_TUTOR_LIST,
  SET_CURRENT_USER,
  RESET_TUTOR_LIST,
} from './actionTypes';

export const resetTutors = () => ({
  type: RESET_TUTOR_LIST,
  payload: [],
});

export const fetchItems = (page = 1, limit = 35) => async (dispatch, getStore) => {
  const {
    subject: subjectId,
    price,
    grade,
    groups,
    levels,
    gradesList,
    schedule,
  } = getStore().filters;
  const subject = groups.find(val => val.id === subjectId);
  const school = theme.themeIndex;

  const params = {
    page,
    limit,
    price,
    grade,
    subject: subjectId,
    school: theme.themeIndex === TEACHERS_THEME ? null : school,
    teacher: theme.themeIndex === TEACHERS_THEME ? 1 : null,
  };

  try {
    Analytics.track('Search for Tutor', {
      filteredByLevel: levels.find(val => val.id === price)?.name,
      filteredByGrade: gradesList.find(val => val.id === grade)?.title,
      filteredBySubject: subject?.title || undefined,
    });

    const response = await axios({
      method: 'POST',
      url: 'tutors',
      params,
      data: {
        schedules: schedule,
      },
    });
    dispatch({ type: FETCH_TUTOR_LIST, payload: response.data });
  } catch (e) {
    console.error(e);
  }
};

export const setCurrentUser = payload => ({ type: SET_CURRENT_USER, payload });

export const fetchCurrentUser = id => async (dispatch, getStore) => {
  const {
    price: levelFilter,
    subject: subjectId,
    grade,
    groups,
    levels,
    gradesList,
  } = getStore().filters;
  try {
    const response = await axios.get(`tutors/${id}`);
    dispatch(setCurrentUser(response.data));

    const { rating, level, price } = response.data;

    const subject = groups.find(val => val.id === subjectId);
    Analytics.track(Events.VIEWED_TUTOR_PROFILE, {
      tutorId: id,
      tutorRate: rating,
      tutorLevel: level,
      tutorPrice: price,
      filteredByLevel: levels.find(val => val.id === levelFilter)?.name,
      filteredByGrade: gradesList.find(val => val.id === grade)?.title,
      filteredBySubject: subject?.title || undefined,
    });
  } catch (e) {
    console.error(e);
  }
};
