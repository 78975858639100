const styles = ({ palette, breakpoints }) => ({
  loader: {
    textAlign: 'center',
  },
  list: {
    overflow: 'visible!important',
    [breakpoints.up('md')]: {
      paddingTop: 24,
    },
  },
  title: {
    marginBottom: 0,
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  filterButton: {
    height: 32,
    width: 100,
    color: '#ffffff',
    backgroundColor: palette.success.main,
    fontWeight: 900,
    boxShadow: 'none',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: palette.success.main,
    '&:hover': {
      backgroundColor: '#ffffff',
      color: palette.success.main,
      boxShadow: 'none',
    },
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

export default styles;
