import { connect } from 'react-redux';
import { fetchItems, resetTutors } from 'store/tutors/actions';
import {
  fetchGroups,
  fetchGrades,
  fetchLevels,
  setFilters,
} from 'store/filters/actions';
import Home from 'modules/Home';
import Page from 'components/Page';

const mapStateToProps = ({ tutors, filters }) => ({
  items: tutors.items,
  meta: tutors.meta,
  current: tutors.current,
  noContent: tutors.noContent,
  groups: filters.groups,
  grades: filters.gradesList,
  levels: filters.levels,
  filters: {
    priceId: filters.price,
    subjectId: filters.subject,
    gradeId: filters.grade,
    dates: filters.dates,
    time: filters.time,
    isRWB: filters.isRWB,
    weekValue: filters.weekValue,
  },
});

const mapDispatchToProps = ({
  fetchGroups,
  fetchGrades,
  fetchLevels,
  fetchItems,
  resetTutors,
  setFilters,
});

const page = connect(mapStateToProps, mapDispatchToProps)(Home);
export default Page({ header: true, title: 'Tutors' })(page);
